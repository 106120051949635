.App {
  text-align: center;
  background-image :url("./icons/calc.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center ;
}
.section {
  background:rgba(53, 94, 59,.9);
  border-radius:10px;
  border-bottom:5px solid #355e3b;
  padding:10px;
}
.invis {
  color:white;
  margin:20px auto;
  padding:0px;
  display:flex;
  flex-direction:column;
  align-items:center;
}
.blinker {
  background: white;
  font-size: 10px;
  margin-left:2px;
  height:32px;
  color:transparent;
  animation: blink 1000ms steps(1, end)  infinite;
}
.blinker2 {
  background:white;
  font-size: 10px;
  margin-left:2px;
  height:32px;
  color:transparent;
  animation: blink 1000ms steps(1, end) 250ms infinite;
}
.title {
  display:flex;
  align-items:center;
  color: white;
  font-size:32px;
  background:rgba(0, 0, 0, .7);
  margin:5px auto;
  padding:5px 20px;
  border-radius:10px;
  width:max-content;
  font-family:Cascadia Code;
}

@keyframes blink {
  50% {
    background:transparent;
  }
}

.unhinderd {
  background-image :url("./icons/unhinderdpic1.jpeg");
  background-repeat: no-repeat;
  background-size:contain;
  background-position: center ;
}

.description {
  display:flex;
  align-items:center;
  color:white;
  font-size:16px;
  background:rgba(0, 0, 0, .5);
  margin:0px;
  padding:5px 20px;
  border-radius:10px;
  width:max-content;
  font-family:Cascadia Code;
}
